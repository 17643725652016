import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Container, Table } from 'react-bootstrap'; // ag grid
import { DatePicker, Empty, Skeleton } from 'antd';
import LdcApi from '../../../LdcApis';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import dayjs from 'dayjs';
import { Spinner } from 'react-bootstrap';

import BonusTracker from '../BonusTracker/BonusTracker';
const ConversionTracker = () => {
  const [finalDateRange, setFinalDateRange] = useState({
    startDate: dayjs().startOf('month').format('YYYY-MM-DD'), // Corrected to use dashes
    endDate: dayjs().format('YYYY-MM-DD'), // Corrected to use dashes
  });
  // const [finalDateRange, setFinalDateRange] = useState({
  //   startDate: '',
  //   endDate: '',
  // });
  const [eodMonthlyData, setEodMonthlyData] = useState([]);
  const [sortAccending, setSortAccending] = useState(false);
  const [sortData, setSortData] = useState([]);

  const { RangePicker } = DatePicker;
  const [DateRangesss, setDateRangess] = useState({
    startDate: '',
    endDate: '',
  });
  const [monthTotals, setmonthTotals] = useState(0);
  const [monthTotalsConverted, setmonthTotalsConverted] = useState(0);
  // const [monthTotalsConverPercentage, setmonthTotalsConverPercentage] = useState(0);
  const [loading, setLoading] = useState(null);
  const dateFormat = 'DD/MM/YYYY';

  // Get the current date
  const now = dayjs();
  // const now = new Date();

  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 1);
  function formatDateToYYYYMMDD(date) {
    // Ensure the parameter is a Date object; if not, use the current date
    const currentDate = date instanceof Date ? date : new Date();
    // Extract year, month, and day
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentDate.getDate().toString().padStart(2, '0');
    // Format the date
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  // Get the first day of the current month
  const firstDayOfPresentMonth = dayjs().startOf('month');

  // Set the default value for the RangePicker
  const defaultValue = [
    firstDayOfPresentMonth, // First day of the present month
    now, // The current date
  ];

  // // // Set the default value for the RangePicker
  // const defaultValue = [
  //   dayjs(formatDateToYYYYMMDD(threeMonthsAgo)),
  //   dayjs(formatDateToYYYYMMDD(now)),
  // ]; // RangePicker takes an array of two moment objects
  // State to hold the selected date range
  const [selectedRange, setSelectedRange] = useState(defaultValue);
  const onChange = (date, dateString) => {
    setFinalDateRange({
      ...DateRangesss,
      startDate: dateString[0],
      endDate: dateString[1],
    });
  };

  useEffect(() => {
    // console.log('defaultValue', defaultValue);
    document.title = 'Conversion Tracker- LDC';
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    setDateRangess({
      ...DateRangesss,
      startDate: moment(firstDay).format('YYYY/MM/DD'),
      endDate: moment(new Date()).format('YYYY/MM/DD'),
    });
  }, []);

  const [apiTrigger, setApiTrigger] = useState(false);

  useEffect(() => {
    // const initialStartDate = dayjs().startOf('month').format('YYYY-MM-DD');
    // const initialEndDate = dayjs().format('YYYY-MM-DD');

    // setDateRangess({
    //   startDate: initialStartDate,
    //   endDate: initialEndDate,
    // });
    // setFinalDateRange({
    //   startDate: initialStartDate,
    //   endDate: initialEndDate,
    // });
    setApiTrigger(true); // Allow API trigger
  }, []);

  useEffect(() => {
    if (!apiTrigger) return;

    const fistDate = finalDateRange.startDate.split('/').reverse().join('/');
    const endDate = finalDateRange.endDate.split('/').reverse().join('/');
    setLoading(true);
    LdcApi({
      method: 'GET',
      url: `LeadManagement/GetLeadConversion?startdate=${
        finalDateRange.startDate === '' ? DateRangesss.startDate : fistDate
      }&enddate=${finalDateRange.startDate === '' ? DateRangesss.endDate : endDate}`,
    })
      .then((res) => {
        if (res?.data.length !== 0) {
          const sortedData = res.data.sort((a, b) => b.converted - a.converted);
          setEodMonthlyData(res?.data);
          setLoading(false);
        } else {
          setLoading(null);
        }
      })
      .catch((err) => setLoading(null));
  }, [finalDateRange, DateRangesss, apiTrigger]);

  // useEffect(() => {
  //   setLoading(true);
  //   let fistDate = finalDateRange.startDate.split('/').reverse().join('/');
  //   let endDate = finalDateRange.endDate.split('/').reverse().join('/');
  //   // console.log('startDate, endDate', DateRangesss.startDate, endDate);
  //   // console.log('fistDate, endDate', fistDate, endDate);

  //   if (
  //     finalDateRange.startDate === finalDateRange.startDate &&
  //     DateRangesss.startDate === DateRangesss.startDate
  //   ) {
  //     LdcApi({
  //       method: 'GET',
  //       //https://api-ldc.goldenbuzz.in/api/LeadManagement/GetLeadConversion?startdate=2023-05-22&enddate=2023-05-23

  //       url: `LeadManagement/GetLeadConversion?startdate=${finalDateRange.startDate === '' ? DateRangesss.startDate : fistDate}&enddate=${
  //         finalDateRange.startDate === '' ? DateRangesss.endDate : endDate
  //       }`,
  //       // url: `LeadManagement/GetLeadConversion?startdate=${DateRangesss?.startDate}&enddate=${DateRangesss?.endDate}`,
  //     })
  //       .then((res) => {
  //         if (res?.data.length !== 0) {
  //           const sortedData = res.data.sort(
  //             (a, b) => b.converted - a.converted
  //           );
  //           console.log('DateRangesss.startDate', DateRangesss.startDate);
  //           console.log('fistDate', fistDate);
  //           setEodMonthlyData(res?.data);

  //           setLoading(false);
  //         } else {
  //           setLoading(null);
  //         }
  //       })
  //       .catch((err) => {
  //         setLoading(null);
  //       });
  //   }
  // }, [finalDateRange, DateRangesss]);

  useEffect(() => {
    const monthTotals = eodMonthlyData.reduce(
      (a, b) => (a === undefined ? 0 : a) + (b === undefined ? 0 : b.handled),
      0
    );
    setmonthTotals(monthTotals);
  }, [eodMonthlyData]);

  useEffect(() => {
    const monthTotalsConverted = eodMonthlyData.reduce(
      (a, b) => (a === undefined ? 0 : a) + (b === undefined ? 0 : b.converted),
      0
    );
    setmonthTotalsConverted(monthTotalsConverted);
  }, [eodMonthlyData]);

  // useEffect(() => {
  //   const monthTotalsConverPercentage = eodMonthlyData.reduce(
  //     (a, b) => (a === undefined ? 0 : a) + (b === undefined ? 0 : b.converPercentage),
  //     0
  //   );
  //   setmonthTotalsConverPercentage(monthTotalsConverPercentage);
  // }, [eodMonthlyData]);

  // const sortLead = () => {
  //   // const sortData = eodMonthlyData.reverse();
  //   eodMonthlyData.sort((a, b) => {
  //     let name1 = a.userName.toLowerCase(),
  //       name2 = b.userName.toLowerCase();
  //     if (sortAccending) {
  //       if (name1 < name2) return -1;
  //       if (name1 > name2) return 1;
  //     } else {
  //       if (name1 < name2) return 1;
  //       if (name1 > name2) return -1;
  //     }
  //     return 0;
  //   });
  //   setSortData(eodMonthlyData);
  // };
  const sortLead = () => {
    const sortedData = [...eodMonthlyData].sort((a, b) => {
      if (sortAccending) {
        return a.converted - b.converted; // Ascending order
      } else {
        return b.converted - a.converted; // Descending order
      }
    });
    setSortData(sortedData);
  };

  useEffect(() => {
    setEodMonthlyData(sortData);
  }, [sortData]);

  return (
    <>
      <Container>
        <div>
          <div style={{ textAlign: 'center' }}>
            <h4>Monthly Performance Statistics</h4>
          </div>
          <div
            className='d-flex align-items-center my-4'
            style={{ color: 'gray', marginBottom: '3rem !important' }}
          >
            <b className='mr-2'>Date Range : </b>
            <RangePicker
              format={dateFormat}
              onChange={onChange}
              defaultValue={selectedRange}
            />
          </div>
          {loading === true ? (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '2rem',
                }}
              >
                {/* <Spinner animation='border' role='status'>
                  <span className='sr-only'>Loading...</span>
                </Spinner> */}
              </div>
              <>
                <Skeleton />
              </>
            </>
          ) : (
            <>
              {loading === null || false ? (
                <>
                  <Empty />
                </>
              ) : (
                <>
                  {' '}
                  <Table
                    responsive
                    className='table table-bordered'
                    id='bootstrap_table'
                    style={{ marginTop: '2rem' }}
                  >
                    <thead
                      style={{ textAlign: 'center' }}
                      id='bootstrap_table_head'
                    >
                      <tr>
                        <th
                          scope='col'
                          style={{ minWidth: 200, cursor: 'pointer' }}
                          onClick={() => {
                            setSortAccending(!sortAccending);
                            sortLead();
                          }}
                        >
                          Lead Owner{' '}
                          {/* {sortAccending ? <BsArrowDown /> : <BsArrowUp />} */}
                        </th>
                        <th scope='col ' style={{ minWidth: 200 }}>
                          <span id='eod_table_heading'>Handled</span>
                        </th>
                        <th scope='col ' style={{ minWidth: 200 }}>
                          <span id='eod_table_heading'>Converted</span>
                        </th>
                        {/* <th
                          scope='col'
                          style={{ minWidth: 200, cursor: 'pointer' }}
                          onClick={() => {
                            setSortAccending(!sortAccending);
                            sortLead();
                          }}
                        >
                          Converted{' '}
                          {sortAccending ? <BsArrowDown /> : <BsArrowUp />}
                        </th> */}
                        <th scope='col ' style={{ minWidth: 200 }}>
                          <span id='eod_table_heading'>Conversion %</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {eodMonthlyData.map((el, i) => {
                        // Condition for alternating rows (add red background for handled and converted columns)
                        const isEvenRow = i % 2 === 1;

                        return (
                          <tr key={i}>
                            <td style={{ background: '#fff3be' }}>
                              <div style={{ textTransform: 'capitalize' }}>
                                {el.userName}
                              </div>
                            </td>
                            <td
                              style={{
                                background: isEvenRow ? '#fff3be' : '', // Add red background to alternate rows
                              }}
                            >
                              <div>{el.handled}</div>
                            </td>
                            <td
                              style={{
                                background: isEvenRow ? '#fff3be' : '', // Add red background to alternate rows
                              }}
                            >
                              <div style={{ textTransform: 'capitalize' }}>
                                {el.converted}
                              </div>
                            </td>
                            <td style={{ background: '#fff3be' }}>
                              <div>
                                {el.converPercentage === 100
                                  ? el.converPercentage
                                  : el.converPercentage.toFixed(2)}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                      <tr
                        className='totalconversionn'
                        style={{ color: '#fff' }}
                      >
                        <td>
                          <b>TOTAL</b>
                        </td>
                        <td>
                          <b>{monthTotals}</b>
                        </td>
                        <td>
                          <b>{monthTotalsConverted}</b>
                        </td>
                        <td>
                          <b>
                            {(
                              (monthTotalsConverted / monthTotals) *
                              100
                            ).toFixed(2)}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </Table>{' '}
                </>
              )}
            </>
          )}
        </div>
      </Container>
      <div style={{ marginTop: '6rem' }}>
        <BonusTracker />
      </div>
    </>
  );
};

export default ConversionTracker;
